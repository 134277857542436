.gift {
    margin-top: 1rem;
    color: #fe6f27;
    padding: 10px 20px;

    &__content {
        p {
            font-size: 1.25rem;
        }        
    }
}

.add-gift {
    h2 {
        font-size: 2rem;
        color: $main-color;
        padding-bottom: 2rem;
    }
    form {
        @include flex(center,flex-start);
        flex-direction: column;

        input {
            width: 100%;
            margin-bottom: 10px;
            padding: 10px;
        }

        select {
            width: 100%;
            margin-bottom: 10px;
            padding: 10px;
        }

        button {
            height: 3rem;
        }
    }

    &__error {
        color:red;
    }
}