.about {
    @include flex(flex-start, flex-start);
    flex-direction: column;

    h1 {
        width: 100%;
        text-align: center;
        margin-bottom: 3rem;
        margin-top: 3rem;
    }

    &__content {
        @include flex(flex-start, flex-start);
        width: 100%;

        @include mobile {
            flex-direction: column;
        }

        &__img {
            width: 50%;
            padding: 3rem;

            @include mobile {
                width: 100%;
            }
        }

        &__desc {
            padding: 3rem;
            width: 50%;
            height: 100%;

            @include mobile {
                width: 100%;
            }

            h2 {
                font-size: 2rem;
                margin-bottom: 2rem;
                letter-spacing: 2px;
            }

            p {
                font-size: 1.25rem;
                letter-spacing: 2px;
                line-height: 2rem;
            }
        }
    }
}
