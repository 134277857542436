canvas {
    max-width: 100%;
}

.drawing-area {
    position: absolute;
    top: 25%;
    left: calc((100% - 45%) / 2);
    // z-index: 10;
    width: 45%;
    height: 65%;
}

.canvas-container {
    position: relative;
    user-select: none;
    // width: 100% !important;
    // height: 100% !important;

    @include mobile {
        width: 100% !important;
        height: 90% !important;
    }
}

#tshirt-canvas,
#tshirt-canvas__back {
    border: 1px solid #ccc;
}

#tshirt-canvas,
#tshirt-canvas__back,
.upper-canvas {
    width: 100% !important;
    height: 100% !important;
}

#tshirt-div__front,
#tshirt-div__back {
    width: 452px;
    height: 548px;
    position: relative;
    background-color: white;

    @include mobile {
        width: 100% !important;
        height: 100% !important;
    }
}

#canvas {
    position: absolute;
    // width: 200px;
    // height: 400px;
    left: 0px;
    top: 0px;
    user-select: none;
    cursor: default;

    // @include mobile {
    //     width: 100% !important;
    //     height: 100% !important;
    // }
}

.custom {
    margin-bottom: 3rem;
    @include flex(center, space-around);

    @include mobile {
        flex-direction: column;
    }

    &__list {
        width: 20%;
        @include flex(flex-start, center);
        flex-direction: column;

        @include mobile {
            width: 100%;
            margin-top: 2rem;
        }
    }

    &__preview {
        @include flex(flex-start, space-around);
        flex-grow: 1;
        position: relative;
        // overflow: hidden;

        // @include mobile {
        //     flex-direction: column;
        // }
    }

    // &__image {
    //     &__front {
    //         transition: all 0.5s ease;
    //         opacity: 0.5;
    //         &.active {
    //             opacity: 1;
    //         }
    //     }

    //     &__back {
    //         opacity: 0.5;
    //         &.active {
    //             opacity: 1;
    //         }
    //     }
    // }

    &__size__modal {
        position: fixed;
        z-index: 101;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: black;
        background-color: rgba(0, 0, 0, 0.4);
        @include flex(center, center);

        transition: all 0.5s ease;

        opacity: 0;
        visibility: hidden;

        &__container {
            width: 60%;
            background-color: white;
            transform: translateY(-500px);
            transition: all 1s ease;

            @include mobile {
                width: 80%;
            }

            &__header {
                @include flex(center, flex-end);

                button {
                    font-size: 1rem;
                    padding: 5px 10px;
                }
            }

            &__content {
                padding: 1rem 2rem;

                h2 {
                    margin-bottom: 1rem;
                }

                table {
                    width: 100%;
                    font-size: 1.5rem;

                    tr {
                        height: 3rem;
                    }

                    tbody {
                        font-weight: 600;
                        color: #fe6f27;
                    }
                }
            }
        }

        &.active {
            opacity: 1;
            visibility: visible;
        }

        &.active &__container {
            transform: translateY(-200px);
            animation: custom-size 0.8s normal;
        }
    }
}

@keyframes custom-size {
    0% {
        transform: translateY(-500px);
        opacity: 1;
        visibility: visible;
    }

    33% {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    66% {
        transform: translateY(-250px);
        opacity: 1;
        visibility: visible;
    }

    100% {
        transform: translateY(-200px);
        opacity: 1;
        visibility: visible;
    }
}
