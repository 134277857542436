.policy-page {
    @include flex(flex-start, flex-start);
    flex-direction: column;
    padding: 2rem;

    h1 {
        width: 100%;
        text-align: center;
        font-size: 3rem;
    }

    &__check {
        padding: 1rem 0;

        h2 {
            margin-bottom: 10px;
        }

        &__content {
            padding: 0 2rem;
            h3 {
                font-size: 1.5rem;
            }

            h3,
            p {
                margin-bottom: 10px;
            }

            p {
                font-size: 1.25rem;
                padding: 0 1rem;
            }
        }

        &__ps {
            padding: 0 2rem;
            h3 {
                font-size: 1.5rem;
            }

            h3,
            p {
                margin-bottom: 10px;
            }

            p {
                font-size: 1.25rem;
                padding: 0 1rem;
            }

            span {
                font-size: 1.5rem;
                font-weight: bold;
            }
        }
    }
}
