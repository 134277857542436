.custom-chat {
    &__btn {
        position: fixed;
        bottom: 10%;
        right: 20px;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

    &__widget {
        position: fixed;
        z-index: 101;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba($color: #000000, $alpha: 0.4);

        opacity: 0;
        visibility: hidden;

        &.active {
            opacity: 1;
            visibility: visible;
        }

        &__content {
            position: absolute;
            bottom: 10%;
            right: 20px;
            width: 30%;
            background-color: white;
            border-radius: 8px;

            @include tablet {
                width: 50%;
            }

            a {
                @include flex(center, flex-start);
                padding: 10px 20px;
                font-size: 1.25rem;
                border-radius: 8px;

                img {
                    width: 30px;
                    padding-right: 10px;
                }

                &:hover {
                    background-color: rgb(238, 238, 238);
                }
            }
        }
    }
}
