.thankyou {
    width: 100%;
    height: 50vh;
    @include flex(center, center);

    &__content {
        text-align: center;

        h3 {
            margin-top: 30px;
            font-size: 1.75rem;
        }

        &__title {
            h1 {
                font-size: 3rem;
                margin-bottom: 10px;
            }

            a {
                color: orange;
            }
        }

        &__btn {
            margin-top: 40px;

            @include mobile {
                @include flex(center, center);
                flex-direction: column;
            }

            a {
                border: 0;
                outline: 0;
                color: #fff;
                font-size: 1.5rem;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
                padding: 20px;
                background-color: black;

                &:nth-child(1) {
                    margin-right: 10px;

                    @include mobile {
                        margin-right: 0;
                        margin-bottom: 10px;
                    }
                }

                @include mobile {
                    width: 90%;
                }
            }
        }

        &__infor {
            @include flex(flex-start, flex-start);
            font-size: 1.25rem;
            margin-top: 20px;

            @include mobile {
                flex-direction: column;
            }

            &__customer {
                width: 50%;
                flex-direction: column;

                @include mobile {
                    width: 100%;
                }

                &__item {
                    @include flex(flex-start, flex-start);
                    padding: 10px 20px;
                    width: 100%;

                    span {
                        width: 40%;
                        text-align: left;
                    }

                    p {
                        flex-grow: 1;
                        font-weight: 500;
                        text-align: left;
                    }
                }

                &__check {
                    h3 {
                        font-size: 1.5rem;
                        margin-top: 10px;
                    }

                    a {
                        @include flex(center, center);
                        color: #007fff;
                    }

                    img {
                        width: 30px;
                        height: 30px;
                        object-fit: cover;
                    }
                }
            }

            &__product {
                flex-grow: 1;
                padding-left: 30px;

                @include mobile {
                    padding-left: 10px;
                    width: 100%;
                }
            }
        }
    }
}
