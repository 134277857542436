.section {
    margin-top: 20px;
    margin-bottom: 40px;

    // border: solid 1px rgb(219, 219, 219);
    border-radius: 5px;
    box-shadow: #959da533 0px 8px 24px;

    & > * ~ * {
        margin-top: 20px;
    }

    &__title {
        @include flex(center, center);
        font-size: 2rem;
        text-transform: capitalize;
        text-align: center;

        transform: translateY(-50%);

        p {
            font-weight: 500;
            background-color: white;
            padding: 0 20px;
            z-index: 99;
        }
    }

    &:nth-child(2),
    &:nth-child(5) {
        border: none;
    }
}
