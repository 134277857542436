.comment {
    border: solid 1px $main-color;
    border-radius: 10px;
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 20px;

    @include flex(flex-start, space-between);

    &__heading {
        &__profile {
            img {
                width: 100px;
                cursor: pointer;
            }
        }
    }

    &__content {
        font-size: 1.25rem;
        width: 80%;
        @include flex(flex-start, flex-start);
        flex-direction: column;
        padding-right: 20px;

        &__reviews {
            @include flex(flex-start, flex-start);
            font-size: 1.5rem;
            margin-bottom: 10px;
            color: $main-color;
        }

        strong {
            margin-bottom: 10px;
        }
    }
}