.product-card {
    text-align: left;
    margin-bottom: 30px;
    position: relative;

    &__image {
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;
        padding-top: 140%;

        img {
            position: absolute;
            top: 0;
            left: 0;

            // transform: translateX(-50%);
            // height: 100%;
            // transform: translateX(-50%) scale(0);

            // &:nth-child(1) {
            //     transform: translateX(-50%) scale(1);
            //     transition: transform 0.5s ease;
            // }

            // &:nth-child(2) {
            //     transform: translateX(-50%) scale(0);
            // }

            // &.active {
            //     transform: translateX(-50%) scale(1);
            // }
        }

        // &:hover img {
        //     &:nth-child(1) {
        //         transform: translateX(-50%) scale(0);
        //     }

        //     &:nth-child(2) {
        //         transform: translateX(-50%) scale(1);
        //         transition: transform 0.5s ease;
        //     }
        // }
    }

    &__name {
        color: black;

        font-size: 14px;
        line-height: 1.2em;
        font-weight: bold;
        margin-bottom: 0.75rem;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    &__price {
        color: $txt-main-color;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;

        @include mobile {
            font-size: 1.25rem;
        }

        &__old {
            color: $txt-second-color;
            margin-left: 20px;
            font-weight: 300;
            font-size: 14px;

            @include mobile {
                font-size: 1rem;
            }
        }
    }

    &__btn {}

    &__switch {
        @include flex(center, left);
        flex-wrap: wrap;
        cursor: pointer;
        margin-bottom: 10px;

        &__image {
            width: 20px;
            height: 20px;
            border-radius: 100rem;
            object-fit: cover;
            overflow: hidden;

            &:hover {
                border: solid 2px $main-color;
            }
        }

        *+* {
            margin-left: 5px;
        }
    }

    &__rating {
        @include flex(center, flex-start);

        &__item {
            color: $main-color;
        }
    }
}