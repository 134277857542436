.product {
    @include flex(flex-start, flex-start);
    flex-wrap: wrap;
    @include mobile {
        flex-direction: column;
    }

    &__image {
        @include flex(flex-start, flex-start);
        width: 60%;
        flex-wrap: wrap;

        &__list__item {
            cursor: pointer;
        }

        @include tablet {
            width: 80%;
        }

        @include tablet {
            width: 100%;
        }

        &__container {
            width: 100%;
            @include flex(flex-start, flex-start);

            &__list {
                width: 7%;
                &__item {
                    cursor: pointer;

                    img {
                        width: 150px;
                    }
                }
            }

            &__main {
                flex-grow: 1;
                padding-top: 90%;
                position: relative;

                position: sticky;
                top: 70px;

                @include mobile {
                    padding-top: 100%;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    height: 100%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    &__info {
        width: 40%;
        padding-left: 20px;
        flex-grow: 1;
        position: sticky;
        // top: $header-height;
        top: 80px;
        padding-top: 10px;

        @include mobile {
            position: relative;
            top: unset;
            width: 100%;
            padding-left: 0px;
        }

        &__title {
            font-size: 2rem;
            font-weight: bold;
        }

        &__item {
            margin-top: 1rem;

            @include mobile {
                margin-top: 0.5rem;
            }

            &__btn {
                @include flex(center, flex-start);

                button {
                    // height: 30px;
                    background-color: white;
                    color: #000;
                    border: 2px solid black;
                    cursor: pointer;
                    box-shadow: 0 2px 4px 2px #e6f1faee;
                    border-radius: 0.5rem;
                    font-size: 1.25rem;
                    font-weight: 600;
                    padding: 5px 10px;

                    &:hover {
                        background-color: #000;
                        color: white;
                    }
                }
            }

            &__price {
                color: $txt-main-color;
                font-size: 1.5rem;
                font-weight: 600;
            }

            &__oldprice {
                margin-left: 2rem;
                color: $txt-second-color;
                font-size: 1.5rem;
            }

            &__title {
                font-size: 1.5rem;
                font-weight: 600;
                // margin-bottom: 0.5rem;
            }

            &__list {
                @include flex(center, flex-start);
                flex-wrap: wrap;

                // @include mobile {
                //     flex-wrap: wrap;
                // }

                button {
                    width: calc(90% + 20px);
                    height: 30px;
                    background-color: white;
                    color: #000;
                    border: 2px solid black;
                    cursor: pointer;
                    box-shadow: 0 2px 4px 2px #e6f1faee;
                    border-radius: 0.5rem;
                    font-size: 1.25rem;
                    font-weight: 600;

                    &:hover {
                        background-color: black;
                        color: white;
                    }
                }

                ul {
                    @include flex(flex-start, flex-start);
                    flex-wrap: wrap;

                    span {
                        // width: 30%;
                        display: flex;
                        flex-direction: column;
                        font-size: 1.25rem;
                        font-weight: 600;
                        text-align: center;
                        padding: 5px 10px;

                        input {
                            border: 1px solid #cccccc;
                            border-radius: 3px;
                            clear: both;
                            display: block;
                            height: 22px;
                            font-size: 12px;
                            padding: 0 2px;
                            text-align: center;
                            width: 58px;
                        }

                        /* Chrome, Safari, Edge, Opera */
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        /* Firefox */
                        input[type="number"] {
                            -moz-appearance: textfield;
                        }
                    }
                }

                &__item {
                    @include flex(center, center);

                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    border: solid 2px $txt-second-color;
                    cursor: pointer;
                    margin-right: 0.5rem;
                    margin-bottom: 0.5rem;

                    @include mobile {
                        width: 40px;
                        height: 40px;
                    }

                    &.active {
                        border: solid 3.5px $main-color;
                    }

                    &:hover {
                        border: solid 3.5px $main-color;
                    }

                    .circle {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;

                        @include mobile {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    &__size {
                        font-size: 1.25rem;
                        text-transform: uppercase;
                    }

                    p {
                        font-size: 1.25rem;
                        font-weight: 500;
                    }
                }
            }

            &__quantity {
                @include flex(center, flex-start);

                :nth-child(1) {
                    border-radius: $border-radius 0 0 $border-radius;
                }

                :nth-child(3) {
                    border-radius: 0 $border-radius $border-radius 0;
                }

                &__btn {
                    @include flex(center, center);

                    width: 30px;
                    height: 30px;
                    border: solid 2px $txt-second-color;
                    font-size: 1.5rem;
                    cursor: pointer;
                }

                &__input {
                    @include flex(center, center);
                    height: 30px;
                    width: 60px;
                    font-size: 1.5rem;
                    border-top: solid 2px $txt-second-color;
                    border-bottom: solid 2px $txt-second-color;
                }

                &__time {
                    @include flex(center, center);
                    padding-left: 20px;
                    font-size: 1.25rem;
                }
            }

            button {
                margin-right: 1rem;
                margin-bottom: 1rem;
            }

            &__upload {
                color: black;
                background-color: white;
                cursor: pointer;
                // user-select: none;
                width: 100%;
                max-width: 300px;
                border: 2px solid black;
                display: flex;
                // flex-direction: row;
                align-items: center;
                padding: 0 1rem;
                border-radius: 0.5rem;
                font-weight: bold;
                box-shadow: 0 2px 4px 2px #e6f1faee;

                visibility: hidden;
                opacity: 0;
                display: none;

                transition: background-color 0.3s;

                &.active {
                    visibility: visible;
                    opacity: 1;
                    display: flex;
                }

                i {
                    font-size: 3rem;
                    fill: currentcolor;
                    display: inline-block;
                    margin-right: 1rem;
                }

                input[type="file"] {
                    display: none;
                }

                &:hover {
                    background-color: black;
                    color: white;
                }
            }

            &__policy {
                padding: 2rem;
                @include flex(start, start);
                flex-wrap: wrap;
                text-align: center;

                &__item {
                    width: 33%;
                    @include flex(center, start);
                    flex-direction: column;

                    i {
                        font-size: 2rem;
                        color: $main-color;
                    }
                }
            }
        }

        &__rating {
            width: 100%;
            @include flex(center, flex-start);
            font-size: 2rem;
            color: $main-color;
        }
    }

    &__rating {
        margin-top: 30px;

        @include flex(flex-start, flex-start);

        &__comment {
            width: 60%;
            @include flex(flex-start, flex-start);
            flex-wrap: wrap;
            height: 400px;
            overflow-y: auto;

            // @include mobile {
            //     height: 200px;
            //     overflow-y: auto;
            // }

            :nth-child(1) {
                width: 100%;
            }
        }

        @include tablet {
            display: none;
        }
    }
}

.product-description {
    width: 100%;
    padding: 2rem 2rem 3rem;
    height: 300px;
    overflow: hidden;
    position: relative;

    @include mobile {
        display: none;
    }

    &.mobile {
        display: none;

        @include mobile {
            display: block;
            height: 200px;
            padding: 0;
        }
    }

    &.expand {
        height: max-content;
    }

    &__title {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 2rem 0;

        @include mobile {
            margin: 0.5rem 0;
        }
    }

    &__content {
        font-size: 1.25rem;
        line-height: 1.875rem;
        text-align: justify;
    }

    &__toggle {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        button {
            background-color: transparent;
        }

        i {
            font-size: 3rem;
            font-weight: 600;
            color: black;
        }
    }
}
