.cart {
    @include flex(flex-start, flex-start);

    @include mobile {
        flex-wrap: wrap;
    }

    &__info {
        width: 40%;
        margin-right: 30px;
        margin-top: 30px;
        box-shadow: $box-shadow;
        position: sticky;
        padding: 20px;
        top: calc(#{$header-shrink-height} + 20px);

        @include mobile {
            width: 100%;
            margin-right: 0;
            margin-bottom: 30px;
            position: unset;
        }

        &__txt {
            & > * {
                margin-bottom: 10px;
            }

            p {
                font-size: 1.12rem;
            }

            &__quantity {
                span {
                    font-weight: 600;
                    font-size: 1.5rem
                }
            }

            &__price {
                @include flex(center, space-between);
                padding: 5px 0;
                font-size: 1.25rem;

                span:nth-child(2) {
                    font-size: 1.5rem;
                    color: $main-color;
                    font-weight: 600;
                }

                &.del {
                    text-decoration:line-through;

                    span:nth-child(2) {
                        font-size: 1.5rem;
                        color: $main-color;
                        font-weight: 600;
                    }
                }

                &__ship {
                    span:nth-child(2) {
                        font-size: 1.25rem;
                        font-weight: normal;
                    }
                }
            }

            &__ship {
                color : #fe6f27;
                font-size: 1.5rem;
                font-weight: 600;

                i {
                    font-size: 2rem;
                    padding-right: 10px;
                }
            }
        }

        &__btn > :nth-child(1) button {
            margin-bottom: 20px;
        }
    }

    &__list {
        flex-grow: 1;
    }

    &__item {
        @include flex(center, flex-start);
        margin-bottom: 20px;
        margin-right: 20px;

        &__image {
            width: 20%;
            margin-right: 20px;

            @include flex(center,center);

            @include mobile {
                width: 40%;
            }

            img {
                height: 150px;
            }
        }

        &__info {
            @include flex(center, space-between);
            flex-grow: 1;
            width: 80%;

            font-size: 1.25rem;

            @include mobile {
                @include flex(flex-start, center);
                flex-direction: column;

                & > * {
                    margin-bottom: 10px;
                }
            }

            &__name {
                color: $main-color;
                width: 20%;

                @include mobile {
                    width: 100%;
                }
            }

            &__del {
                font-size: 1.5rem;

                i {
                    cursor: pointer;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
}
