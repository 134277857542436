.header {
    background-color: $main-color;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // z-index: 99;
    height: $header-height;
    transition: height 0.5s ease;
    color: $txt-main-color;

    @include tablet {
        height: $header-tablet-height;
        box-shadow: $box-shadow;
    }

    @include mobile {
        height: $header-mobile-height;
    }

    .container {
        height: 100%;
    }

    &.shrink {
        height: $header-shrink-height;
        box-shadow: $box-shadow;
        z-index: 100;

        @include mobile {
            height: $header-mobile-height;
        }
    }

    &__logo {
        @include flex(center, center);
        position: absolute;
        inset: 0;
        pointer-events: none;

        @include tablet {
            justify-content: flex-start;
            padding-left: 6rem;
        }

        @include mobile {
            justify-content: flex-start;
            padding-left: 4rem;
        }

        img {
            width: 150px;
            height: 70px;
            object-fit: cover;

            @include mobile {
                width: auto;
                height: $header-mobile-height;
                transform: scale(0.9);
            }

            @media screen and (max-width: 370px) {
                width: 130px;
                height: 30px;
            }
        }

        a {
            pointer-events: all;
        }
    }

    &__menu {
        @include flex(center, space-between);
        height: 100%;
        font-size: 1.5rem;
        color: $txt-white;

        &__item ~ &__item {
            margin-left: 39px;

            @include tablet {
                margin-left: 10px;
            }

            @include mobile {
                margin-left: 5px;
            }

            @media screen and (max-width: 1250px) and (min-width: 1025px) {
                margin-left: 10px;
                font-size: 1.25rem;
            }
        }

        &__item.active {
            font-weight: 600;
            color: $txt-white;
        }

        &__item:hover {
            color: $txt-white;
            font-weight: 600;
        }

        &__left,
        &__right {
            @include flex(center, center);
            height: 100%;
        }

        &__right {
            cursor: pointer;
            font-size: 2.25rem;
            position: relative;

            i {
                font-size: 2.5rem;
            }

            a {
                @include flex(center, center);
                position: relative;
            }

            @include mobile {
                font-size: 2rem;
            }

            @include tablet {
                margin-left: 10px;
            }

            &__item {
                @include flex(center, flex-end);
                position: relative;

                &:nth-child(2) {
                    border: solid 2px $main-color;
                    border-radius: 5px;

                    span {
                        font-size: 1.5rem;
                        font-weight: 500;

                        @include mobile {
                            font-size: 1rem;
                            width: 3rem;
                        }
                    }

                    &:hover .header__menu__right__item__card {
                        visibility: visible;
                        opacity: 1;
                        transform: translateX(50px);

                        @include mobile {
                            visibility: hidden;
                            opacity: 0;
                            transform: translateX(10px);
                        }
                    }
                }

                &__quantity {
                    position: absolute;
                    height: 1.5rem;
                    width: 1.5rem;
                    border-radius: 50%;
                    top: 0;
                    right: 0;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: black;
                    font-size: 15px;
                    color: white;
                    pointer-events: none;

                    @include mobile {
                        height: 1.25rem;
                        width: 1.25rem;
                        font-size: 1rem;
                    }
                }

                &:hover &__content {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }

                &__content {
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    width: 150px;
                    opacity: 0;
                    visibility: hidden;

                    transform: translateY(50px);
                    transition: transform 0.3s ease;

                    span {
                        margin-top: 10px;
                        font-size: 1.5rem;
                        text-align: right;
                    }
                }

                &__card {
                    pointer-events: all;
                    position: absolute;
                    background-color: rgb(75, 75, 75);
                    width: 300px;
                    top: 110%;
                    right: 0;
                    z-index: 99;

                    visibility: hidden;
                    opacity: 0;
                    transform: translateX(100%);
                    transition: all 0.7s ease;

                    &.active {
                        visibility: visible;
                        opacity: 0.75;
                        transform: translateX(50px);

                        @include mobile {
                            transform: translateX(10px);
                        }
                    }

                    @include mobile {
                        // display: none;
                        width: 200px;
                        transform: translateX(10px);
                    }

                    &__item {
                        @include flex(flex-start, flex-start);
                        padding-top: 5px;

                        &__image {
                            img {
                                width: 30px;
                                height: 30px;
                                object-fit: cover;
                            }
                        }

                        &__info {
                            @include flex(flex-start, space-around);
                            color: white;
                            flex-grow: 1;
                            padding-left: 10px;
                            font-size: 1rem;

                            flex-wrap: wrap;

                            &__price {
                                font-size: 1rem;
                            }

                            a {
                                &:hover {
                                    color: $txt-white;
                                }
                            }

                            &__del {
                                padding-left: 10px;
                                cursor: pointer;

                                i {
                                    font-size: 1rem !important;
                                }

                                &:hover {
                                    color: $txt-white;
                                }
                            }

                            &__name {
                                width: 100%;
                            }
                        }
                    }

                    &__btn {
                        @include flex(center, center);

                        a {
                            text-align: center;
                            width: 100%;
                            border: 0;
                            outline: 0;
                            color: $btn-main-color;
                            font-size: 1rem;
                            font-weight: 600;
                            text-transform: uppercase;
                            background-color: #fe6f27;

                            cursor: pointer;
                            pointer-events: all;
                            padding-top: 3px;

                            // p {
                            //     padding-top: 3px;
                            //     font-size: 0.9rem;
                            //     font-weight: normal;
                            // }
                        }
                    }
                }

                &__input {
                    height: 40px;
                    background: none;
                    border: none;
                    color: white;
                    border-radius: $border-radius;
                    padding: 0 10px;

                    @include mobile {
                        display: none;
                    }

                    &::placeholder {
                        color: white;
                    }
                    &::-webkit-input-placeholder {
                        color: white;
                    }

                    &:-ms-input-placeholder {
                        color: white;
                    }

                    &:focus {
                        outline: none !important;
                        border: 1px solid $main-color;
                        box-shadow: 0 0 10px #719ece;
                    }
                }

                &__button {
                    height: $header-mobile-height;
                    @include flex(center, center);
                }

                &__call {
                    padding-left: 5px;

                    p {
                        font-size: 1.25rem;
                        font-weight: 500;

                        &:nth-child(2) {
                            color: $txt-white;
                        }

                        @include mobile {
                            font-size: 1rem;
                        }
                    }
                }

                &__cart {
                    height: 40px;
                    width: 40px;

                    @include mobile {
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }

        &__left {
            z-index: 100;
            &__close {
                display: none;
            }

            &__item {
                position: relative;
                cursor: pointer;

                @include mobile {
                    margin-top: 50px;
                    font-size: 1.25rem;
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -5px;
                    height: 2px;
                    width: 0;
                    background-color: $main-color;
                    transition: width 0.3s ease;
                }

                &.active::before,
                &:hover::before {
                    width: 100%;
                }

                @media screen and (max-width: 360px) {
                    a {
                        font-size: 1.25rem;
                    }
                }
            }

            @include tablet {
                flex-direction: column;
                background-color: $main-color;
                position: absolute;
                top: 0;
                left: 0;
                width: 20%;
                height: 100vh;
                justify-content: flex-start;
                padding-top: 30px;

                transform: translateX(-100%);
                transition: transform 0.5s ease;

                &.active {
                    transform: translateX(0);
                }

                &__item ~ &__item {
                    margin-left: unset;
                    margin-top: 20px;
                }

                &__close {
                    display: block;
                    position: absolute;
                    left: 10px;
                    font-size: 2.5rem;
                }
            }
        }

        &__mobile-toggle {
            display: none;

            @include tablet {
                display: block;
                font-size: 2.5rem;
            }
        }
    }
}
