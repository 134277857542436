$main-bg: #f2cbe2;
$main-color: #f567c5;

$txt-main-color: black;
$txt-second-color: #888888;
$txt-white: #fff;

$btn-main-bg: $main-color;
$btn-main-color: #fff;

$header-height: 70px;
$header-tablet-height: 70px;
$header-mobile-height: 40px;
$header-shrink-height: 70px;

$border-radius: 10px;

$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

$colors: (
    "xanh-duong": #4267b2,
    "cam": #fbb96b,
    "trang": #fff,
    "den": #000,
    "main": $main-color,
    "do": #ff0000,
    "yellow": rgb(236, 236, 0),
    "xanh-la": rgb(141, 194, 141),
    "nau": rgb(126, 91, 91),
    "navy": navy,
    "xam": gray,
    "be": #ac9c85,
    "reu": #3e604f,
    "vang": #f2cf1d,
    "hong": #b9a7a5,
    "xanh-dam": #000a4a,
    "xanh-nhat": #8d99e1
);

$mobile-width: 600px;
$tablet-width: 1024px;

$numbers: (0, 1, 2, 3, 4, 5, 6, 7, 8);
