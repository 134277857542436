.checkout {
    @include flex(flex-start, flex-start);

    @include mobile {
        @include flex(flex-start, flex-start);
        flex-direction: column;
        flex-wrap: wrap;
    }

    h2 {
        margin-bottom: 30px;
    }

    &__list {
        width: 100%;
        margin-right: 30px;
        padding: 10px;

        @include mobile {
            width: 100%;
            margin-right: 0;
        }

        &__item {
            @include flex(center, flex-start);

            margin-bottom: 10px;

            &__image {
                @include flex(center, center);
                width: 20%;

                @include mobile {
                    width: 30%;
                }

                margin-right: 20px;

                img {
                    height: 100px;
                }
            }

            &__info {
                @include flex(center, space-between);
                flex-grow: 1;

                @include mobile {
                    @include flex(flex-start, center);
                    flex-direction: column;

                    & > * {
                        margin-bottom: 10px;
                    }
                }

                &__name {
                    width: 40%;
                    color: $main-color;

                    @include mobile {
                        width: 100%;
                    }
                }

                &__name,
                &__price {
                    font-size: 1.25rem;
                }

                &__quantity {
                    font-size: 1.25rem;
                }
            }
        }

        &__total {
            @include flex(flex-start, space-between);
            color: $main-color;

            &__price {
                font-size: 1.25rem;
                font-weight: 600;
            }
        }
    }

    &__customer {
        padding: 10px;
        flex-grow: 1;
        flex-wrap: wrap;
        position: sticky;
        top: 170px;

        h2 {
            margin-bottom: 10px;
        }

        @include mobile {
            width: 100%;
            position: unset;
        }

        &__info {
            form {
                @include flex(flex-start, center);
                flex-wrap: wrap;

                & > * {
                    margin-bottom: 10px;
                }

                input {
                    height: 40px;
                    &.error {
                        border: 1px solid #ff2f2f;
                    }
                }

                span {
                    margin-left: 20px;
                    color: #ff2f2f;
                }

                button {
                    width: calc(90% + 20px);
                    height: 40px;

                    background-color: white;
                    color: $main-color;
                    border: 2px solid black;

                    &:hover {
                        background-color: black;
                        color: white;
                    }

                    @include mobile {
                        background-color: black;
                        color: white;
                    }
                }
            }

            &__name {
                width: 45%;
                margin-right: 20px;
                input {
                    background: #fff;
                    border: 1px solid #d9d9d9;
                    box-sizing: border-box;
                    border-radius: 16px;
                    height: 40px;
                    width: 100%;
                    padding: 5px 20px;
                    transition: all 0.2s;
                }
            }
            &__sdt {
                width: 45%;
                input {
                    background: #fff;
                    border: 1px solid #d9d9d9;
                    box-sizing: border-box;
                    border-radius: 16px;
                    height: 40px;
                    width: 100%;
                    padding: 5px 20px;
                    transition: all 0.2s;
                }
            }
            &__add {
                width: calc(90% + 20px);
                input {
                    background: #fff;
                    border: 1px solid #d9d9d9;
                    box-sizing: border-box;
                    border-radius: 16px;
                    height: 40px;
                    width: 100%;
                    padding: 5px 20px;
                    transition: all 0.2s;
                }
            }
            &__email {
                width: calc(90% + 20px);
                input {
                    background: #fff;
                    border: 1px solid #d9d9d9;
                    box-sizing: border-box;
                    border-radius: 16px;
                    height: 40px;
                    width: 100%;
                    padding: 5px 20px;
                    transition: all 0.2s;
                }

                select {
                    width: 100%;
                    background: #fff;
                    border: 1px solid #d9d9d9;
                    box-sizing: border-box;
                    border-radius: 16px;
                    height: 40px;
                    width: 100%;
                    padding: 5px 20px;
                    transition: all 0.2s;
                }
            }

            &__payment {
                h3 {
                    margin-bottom: 10px;
                }

                width: 100%;
                @include flex(center, flex-start);
                flex-direction: column;

                &__item {
                    @include flex(flex-start, space-between);
                    // flex-direction: column;
                    flex-wrap: wrap;

                    border-radius: 10px;
                    width: calc(90% + 20px);
                    // padding: 5px 20px;
                    cursor: pointer;

                    label {
                        @include flex(center, flex-start);
                        cursor: pointer;
                        width: 45%;
                        height: 60px;
                        border: 1px solid #d9d9d9;
                        border-radius: 16px;
                        opacity: 0.6;
                        padding: 10px 10px;
                        margin-bottom: 10px;

                        @include mobile {
                            width: 100%;
                        }

                        &:hover {
                            opacity: 1;
                            border: 2px solid $main-color;
                        }

                        &.checked {
                            opacity: 1;
                            border: 2px solid $main-color;
                        }

                        input {
                            cursor: pointer;
                        }

                        &:nth-child(2) {
                            img {
                                width: 50px;
                            }
                        }
                    }

                    &__content {
                        margin-left: 10px;
                        width: 100%;
                        height: 100%;
                        @include flex(center, space-between);
                        // flex-direction: column;

                        // @include mobile {
                        //     max-width: 100%;
                        // }

                        // img {
                        //     max-width: 70px;
                        //     max-height: 100%;
                        // }

                        &__img {
                            @include flex(center, center);
                            // padding: 10px;
                            width: 70px;
                        }

                        &__des {
                            padding-left: 10px;
                            width: 80%;
                            @include flex(flex-start, center);
                            flex-direction: column;
                        }
                    }
                }
            }

            &__total {
                width: calc(90% + 20px);
                padding: 0 5px;

                div {
                    :nth-child(1) {
                        margin-left: 0px;
                    }
                }

                div:nth-child(4) {
                    h2 {
                        margin-bottom: 0px;
                    }
                }
            }

            &__coupon {
                width: calc(90% + 20px);
                position: relative;

                @include flex(flex-start, space-between);

                input {
                    border: 2px solid #fe6f27;
                    box-sizing: border-box;
                    border-radius: 16px;
                    height: 40px;
                    width: 70%;
                    padding: 5px 20px;
                    transition: all 0.2s;
                    font-weight: 600;
                    z-index: 2;

                    &:focus {
                        outline: none;
                        color: #fe6f27;
                    }
                }

                p {
                    position: absolute;
                    right: 0;
                    width: 40%;
                    border-radius: 16px;
                    @include flex(center, center);
                    height: 40px;
                    background-color: white;
                    color: #000;
                    border: 2px solid #fe6f27;
                    font-size: 1.5rem;
                    font-weight: 500;
                    cursor: pointer;
                    padding-left: 8%;

                    &:hover {
                        flex-grow: 1;
                        background-color: #fe6f27;
                        color: white;
                    }
                }

                &__content {
                    width: calc(90% + 20px);
                    font-size: 1.25rem;
                    color: #fe6f27;
                    text-align: center;
                }
            }
        }
    }
}
