.check-phone {
    padding: 4rem;

    @include mobile {
        padding: 0;
    }

    &__list {
        hr {
            margin-bottom: 20px;
            margin-top: 5px;
        }

        table {
            width: 100%;
            font-size: 1.5rem;
            padding: 1rem;
            margin-bottom: 1rem;

            tr {
                height: 3rem;
            }

            tbody {
                font-weight: 600;
                color: #fe6f27;
            }
        }
    }

    &__check {
        hr {
            margin-bottom: 20px;
            margin-top: 5px;
        }

        form {
            padding: 0 3rem;

            @include flex(center, center);

            @include mobile {
                padding: 0;
            }

            input {
                width: 80%;
                height: 30px;
                padding: 0 2rem;
                font-size: 20px;
                border: solid 1px $main-color;

                @include mobile {
                    font-size: 16px;
                }
            }

            button {
                width: 20%;
                height: 30px;
            }
        }

        table {
            width: 100%;
            font-size: 1.5rem;
            padding: 1rem;
            margin-bottom: 1rem;

            @include mobile {
                padding: 0;
                font-size: 1.25rem;
            }

            tr {
                height: 3rem;
            }

            tbody {
                font-weight: 600;
                color: #fe6f27;
            }
        }

        &__info {
            padding: 0 3rem;

            @include mobile {
                padding: 0;
            }
        }
    }
}
