.customer__rating {
    width: 40%;
    padding: 1rem;

    @include mobile {
        width: 100%;
    }

    &__container {
        width: 100%;
        @include flex(center, center);

        &__content {
            width: 100%;
            padding: 2rem;
            background-color: rgba(149, 157, 165, 0.2);
            border-radius: $border-radius;
            @include flex(center, center);
            flex-direction: column;

            &__rate {
                font-size: 3rem;
                font-weight: bold;
            }

            &__stars {
                @include flex(flex-start, center);
                font-size: 2.5rem;
                color: $main-color;
            }

            &__quantity {
                font-size: 14px;
                font-style: italic;
            }
        }
    }

    form {
        @include flex(flex-start, flex-start);
        flex-direction: column;
        //margin: 15px;
        // border: solid 1px black;
        // box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
        // border-radius: $border-radius;
    }

    &__infor {
        @include flex(center, space-between);
        width: 100%;

        input {
            width: 40%;
            height: 30px;
            padding-left: 10px;
            // font-size: 1.25rem;
            font-weight: 600;
            border-radius: $border-radius - 4px;

            ::-ms-input-placeholder {
                font-size: 5px;
            }

            ::placeholder {
                font-size: 5px;
            }
        }

        &__star {
            @include flex(flex-start, flex-start);
            padding-left: 20px;
            // padding-right: 40px;
            font-size: 2.5rem;
            cursor: pointer;
            color: $main-color;
        }
    }

    &__comment {
        width: 100%;
        padding: 10px 0px;

        textarea {
            width: 100%;
            height: 70px;
            padding-left: 10px;
            font-size: 1.25rem;
            border-radius: $border-radius;
        }
    }

    &__btn {
        border: 0;
        outline: 0;
        color: $btn-main-color;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        width: 100%;
        background-color: black;
        height: 35px;
        border-radius: $border-radius;
    }

    &__image {
        margin-top: 10px;
    }
}

.product__image__rating__mobile {
    margin-top: 30px;
    display: none;

    @include tablet {
        display: unset;
        width: 100%;
    }
}