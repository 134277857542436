.drop-file {
    width: 100%;

    @include flex(flex-start, flex-start);
    margin-bottom: 2rem;

    &__input {
        position: relative;
        width: 300px;
        height: 30px;
        border: 1px dashed $main-color;
        border-radius: 10px 0 0 10px;
        background-color: #f5f8ff;
        @include flex(center, center);
        padding: 1rem 2rem;
        input {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;

            &:hover,
            &:-moz-drag-over {
                opacity: 0.6;
            }
        }

        &__label {
            text-align: center;
            color: $txt-second-color;
            font-weight: 600;

            @include flex(center, center);

            i {
                font-size: 2rem;
            }
        }
    }

    button {
        margin-left: -10px;
        z-index: 999;
        border: solid 1px $main-color;
    }
}
