.wrapper-class {
    width: 100%;
    padding: 1rem;
    // border: 1px solid #ccc;
}
.editor-class {
    padding: 1rem;
    border: 1px solid #ccc;
}
.toolbar-class {
    border: 1px solid #ccc;
}