.hero-slider {
    margin-bottom: 50px;

    @include tablet {
        margin-top: calc(#{$header-tablet-height} * -1);
    }

    @include mobile {
        margin-top: calc(#{$header-mobile-height} * -1);
    }

    &__item {
        @include flex(flex-start, flex-start);

        pointer-events: none;

        @include tablet {
            flex-direction: column-reverse;
            padding-top: $header-tablet-height;
        }

        @include mobile {
            padding-top: $header-mobile-height;
        }

        &__image {
            width: 100%;
            height: 100%;
            pointer-events: none;

            @include tablet {
                width: 100%;
                height: 60%;
                @include flex(center, center);
            }

            img {
                @include tablet {
                    height: 100%;
                }
            }
        }
    }
}
