.tag {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 2.5rem;
    z-index: 98;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    width: 100%;

    @include flex(center, flex-end);

    & > * {
        margin-right: 5px;
        border-radius: $border-radius;
        width: 10%;
        height: 20px;
        color: $txt-white;
        @include flex(center, center);
        font-size: 0.75rem;
        font-weight: bold;
    }

    &__new {
        background-color: rgb(3, 3, 136);
    }

    &__hot {
        background-color: rgb(121, 9, 9);
    }

    &__sale {
        background-color: rgb(0, 105, 0);
    }

    // &:before,
    // &:after {
    //     content: "";
    //     position: absolute;
    //     background: inherit;
    //     height: inherit;
    //     width: inherit;
    //     top: 0;
    //     left: 0;
    //     z-index: -1;
    //     transform: rotate(30deg);
    // }
    // &:after {
    //     transform: rotate(60deg);
    // }
}

// @keyframes beat {
//     from {
//         transform: rotate(-20deg) scale(1);
//     }
//     to {
//         transform: rotate(-20deg) scale(1.1);
//     }
// }
