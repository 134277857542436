.contact {
    &__slide {
        width: 100%;
        height: 30px;
        overflow: hidden;
        @include flex(center, center);
        flex-direction: column;
        background-color: $main-color;
        position: relative;
        z-index: -1;

        @include mobile {
            margin-top: 100px;
        }

        &__item {
            color: white;
            position: absolute;
            transition: all 1s ease;

            &:nth-child(1) {
                transform: translateY(-50px);

                &.active {
                    transform: translateY(0);
                }
            }

            &:nth-child(2) {
                transform: translateY(50px);

                &.active {
                    transform: translateY(0);
                }
            }
        }
    }

    &__content {
        padding-top: 3rem;

        &__item {
            padding: 2rem;

            h1 {
                padding-bottom: 2rem;
                font-size: 3rem;
            }

            &__item {
                @include flex(flex-start, flex-end);
                flex-direction: column;
                font-size: 1.25rem;

                h2 {
                    padding-bottom: 10px;
                }

                span {
                    padding-bottom: 10px;
                }

                hr {
                    margin-top: 10px;
                    width: 100%;
                    height: 2px;
                    background-color: $main-color;
                }
            }
        }
    }
}
