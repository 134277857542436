.loading {
    justify-content:center;
    display:flex;
    background:#ffffff;
    align-items:center;
    font-family: 'poppins' , sans-serif;

    position: fixed;
    z-index: 101;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;

    &.active{
        opacity: 1;
        visibility: visible;
    }

    &__content {
        width:300px;
        height:300px;
        border-radius:50%;
        background:#363636;
        position:relative;
        justify-content:center;
        display:flex;
        align-items:center;

        &::before {
            content:"";
            position:absolute;
            width:93%;
            height:93%;
            left:1%;
            right:1%;
            bottom:1%;
            top:1%;
            border:7px solid white;
            border-radius:50%;
        }

        h1 {
            position:relative;
            font-size:40px;
            letter-spacing:3px;
            color:transparent;
            -webkit-text-stroke:0.50px #fe6f27;

            &::before {
                content:attr(data-text);
                position:absolute;
                width:0%;
                height:100%;
                color:#fe6f27;
                overflow:hidden;
                animation: fill 1s linear infinite;
            }

            
        }
    }
}

@keyframes fill {
    0%,10%,100% {
        width:0%;
    }

    70%,90% {
        width:100%;
    }
}