.admin {
    @include flex(flex-start, flex-start);

    h1 {
        color: $main-color;
    }

    @include mobile {
        flex-direction: column;
    }

    &__menu__left {
        width: 20%;
        position: sticky;
        top: 170px;

        h1 {
            margin-bottom: 10px;
        }

        @include mobile {
            width: 100%;
            text-align: center;
            position: unset;
        }

        &__close {
            font-size: 1.5rem;
            display: none;
        }

        li {
            font-size: 1.25rem;
            margin-bottom: 10px;

            cursor: pointer;

            &:hover {
                color: $main-color;
                font-weight: 600;
            }
            &.active {
                color: $main-color;
                font-weight: 600;
            }
        }
    }

    &__content {
        flex-grow: 1;
        width: 80%;

        @include mobile {
            width: 100%;
        }

        h1 {
            color: $main-color;
            text-align: center;
        }

        &__add__product {
            form {
                margin-top: 10px;
                @include flex(center, center);
                flex-direction: column;

                & > * {
                    margin-bottom: 20px;
                }

                input,
                textarea,
                select {
                    height: 40px;
                    &.error {
                        border: 1px solid #ff2f2f;
                    }

                    background: #fff;
                    border: 1px solid #d9d9d9;
                    box-sizing: border-box;
                    border-radius: 16px;
                    height: 40px;
                    width: 100%;
                    padding: 5px 20px;
                    transition: all 0.2s;

                    // &:nth-last-child(3) {
                    //     height: 100px;
                    // }
                }

                // span {
                //     margin-left: 20px;
                //     color: #ff2f2f;
                // }

                button {
                    width: 25%;
                    height: 40px;

                    @include mobile {
                        width: 100%;
                    }
                }

                // img {
                //     width: 100px;
                // }
            }
        }

        &__list__product {
            &__item {
                @include flex(center, flex-start);
                padding-bottom: 10px;

                &__image {
                    position: relative;
                    width: 150px;
                    height: 150px;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        max-width: 100px;
                        height: 100%;

                        // &:nth-child(1) {
                        //     transform: translateX(-50%) scale(1);
                        //     transition: transform 0.5s ease;

                        //     @include mobile {
                        //         transform: translateX(0) scale(1);
                        //         transition: transform 0.5s ease;
                        //     }
                        // }

                        // &:nth-child(2) {
                        //     transform: translateX(-50%) scale(0);
                        // }
                    }
                    // &:hover img {
                    //     &:nth-child(1) {
                    //         transform: translateX(-50%) scale(0);
                    //     }

                    //     &:nth-child(2) {
                    //         transform: translateX(-50%) scale(1);
                    //         transition: transform 0.5s ease;
                    //     }
                    // }
                }
            }
        }

        &__list__order {
            button {
                padding: 0.5rem 2rem;
            }

            table {
                width: 100%;

                text-align: left;
                tr {
                    height: 50px;

                    @include mobile {
                        height: 30px;
                    }
                }

                thead {
                    font-size: 1.25rem;

                    @include mobile {
                        font-size: 1rem;
                    }
                }

                tbody {
                    font-size: 1.25rem;

                    @include mobile {
                        font-size: 1rem;
                    }
                }

                i {
                    font-size: 2rem;
                    cursor: pointer;

                    @include mobile {
                        font-size: 1rem;
                    }

                    &:hover {
                        color: $main-color;
                    }
                }

                img {
                    width: 100px;
                }
            }

            &__add {
                width: 30%;
            }
        }
    }
}

.edit-image {
    width: 100px;
}
