.order-status {
    select {
        height: 30px;
        border: none;
        font-size: 1.25rem;
        font-weight: 600;
        text-align: center;
        cursor: pointer;

        border-radius: 10px;

        -webkit-appearance: none;
        -moz-appearance: none;

        &.green {
            background-color: green;
            color: white;
        }

        &.red {
            background-color: red;
            color: white;
        }

        &.blue {
            background-color: blue;
            color: white;
        }

        &.orange {
            background-color: orange;
            color: white;
        }

        &.pink {
            background-color: pink;
            color: white;
        }
    }
}
