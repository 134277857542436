.policy {
    padding: 10px;
    color: #fe6f27;
    font-size: 1.25rem;
    line-height: 1.75rem;

    &__title {
        display: flex;
        padding-left: 10px;

        i {
            font-size: 1.25rem;
            padding-right: 10px;
        }

        p {
            font-size: 1.5rem;
        }
    }

    &__update {
        font-size: 1.25rem;

        &__title {
            font-size: 1.5rem;
            font-weight: 600;
        }

        i {
            font-size: 2rem;
            padding-right: 10px;
        }

        &__next {
            padding-left: 30px;

            p {
                font-size: 1.5rem;
            }
        }
    }
}
