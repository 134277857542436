.preview-image {
    position: fixed;
    z-index: 101;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba($color: #000000, $alpha: 0.4);

    opacity: 0;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    &__close {
        position: absolute;
        top: 3rem;
        right: 3rem;
        font-size: 4rem;
        color: white;
        cursor: pointer;
    }

    &__image {
        @include flex(center, center);
    }
}
